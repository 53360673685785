/* TODO: Trying to convert to tailwind for this animation but it seems like its complicated than expected. Leaving this in for now */
.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav span:before,
.nav span:after {
  content: ''; /* content-none */
  position: absolute; /* absolute */
  width: 0%; /* w-0 */
  height: 1px; /* h-px */
  top: 50%; /* top-2/4 */
  margin-top: -0.5px; /* -mt-[0.5px] */
  background: #fff; /* bg-white */
}

/* before: top-2/4 content-none absolute w-0 h-px -mt-[0.5px] bg-white */
/* after:bg-white right-[2.5px] transition-width duration-[0.8s] ease-[cubic-bezier(0.22, 0.61, 0.36, 1)] top-2/4 top-2/4 content-none absolute w-0 h-px -mt-[0.5px] */

.nav span:before {
  left: -2.5px;
}

/* before:-left-[2.5px] */

.nav span:after {
  right: 2.5px;
  background: #fff;
  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

/* after:bg-white right-[2.5px] transition-width duration-[0.8s] ease-[cubic-bezier(0.22, 0.61, 0.36, 1)] */

.nav span:active:before {
  background: #fff;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

/* hover:before:bg-white w-full transition-width duration-[0.5s] ease-[cubic-bezier(0.22, 0.61, 0.36, 1)] */

.nav span:active:after {
  background: transparent;
  width: 100%;
  transition: 0s;
}

/* hover:after:bg-transparent w-full duration-[0s] */
